export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../components/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const LazyPicture = () => import('../../components/LazyPicture.vue' /* webpackChunkName: "components/lazy-picture" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const ProductNotify = () => import('../../components/ProductNotify.vue' /* webpackChunkName: "components/product-notify" */).then(c => wrapFunctional(c.default || c))
export const SeoContent = () => import('../../components/SeoContent.vue' /* webpackChunkName: "components/seo-content" */).then(c => wrapFunctional(c.default || c))
export const SocialShare = () => import('../../components/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c))
export const TopMarquee = () => import('../../components/TopMarquee.vue' /* webpackChunkName: "components/top-marquee" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
